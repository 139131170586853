import React, { useEffect, useState } from 'react';
import './OrderViewModal.css';
import Moment from 'moment';
import myAxios from '../AxiosUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Modal({ setOpenModal, modalData, setRefresh, handleUpdate }) {
    const [startDate, setStartDate] = useState(new Date());
    const [status, setStatus] = useState(Number(modalData.orderStatus));
    const [location, setLocation] = useState(modalData.deliveryCharge);
    const [ReturnAmount, setReturnAmount] = useState(modalData.ReturnAmount);
    const uid = localStorage.getItem('id');
    const [Dhaka, setDhaka] = useState();
    const [Fake, setFake] = useState(modalData.Fake);
    const [Outside, setOutside] = useState();
    const [CourierReturn, setCourierReturn] = useState(
        modalData.CourierReturn ? modalData.CourierReturn : 'no'
    );

    const [Cmessage, setCmessage] = useState('');

    const [loading, setLoading] = useState(false);
    const [DuplicateOrder, setDuplicateOrder] = useState([]);

    let success_rate;
    let successful_delivery;
    let total_delivery;
    let is_new;

    if (modalData.FraudPathao.length > 0 && modalData.FraudPathao[0].data) {
        is_new = modalData.FraudPathao[0].data.is_new;
        success_rate = modalData.FraudPathao[0].data.success_rate;
        if (is_new === false) {
            successful_delivery =
                modalData.FraudPathao[0].data.customer.successful_delivery;
            total_delivery =
                modalData.FraudPathao[0].data.customer.total_delivery;
        }
    }

    useEffect(() => {
        myAxios
            .get(`get-domain/${uid}`)
            .then((res) => {
                setDhaka(parseInt(res.data[0].Dhaka));
                setOutside(parseInt(res.data[0].Outside));
            })
            .catch((err) => console.log(err.response.data));
    }, []);

    useEffect(() => {
        myAxios
            .get(`/get-duplicate/${modalData.cMobile}`)
            .then((res) => {
                setDuplicateOrder(res.data);
            })
            .catch((err) => console.log(err));
    }, [modalData._id]);

    let futureDate;
    if (startDate > new Date()) {
        futureDate = startDate;
    }
    const submitH = async () => {
        if (!status || !location) {
            toast.error('Status, Location, Product Required.');
            return;
        }
        setLoading(true);
        const url = `edit-order-status/${modalData._id}`;
        const formData = { status, location, startDate: futureDate, ReturnAmount, CourierReturn };
        try {
            const res = await myAxios.post(url, formData);
            toast.success(res.data.message);
            setOpenModal(false);
            setRefresh(true);
            handleUpdate();
        } catch (error) {
            console.log(error.response.data);
        } finally {
            setLoading(false);
        }
    };
    const WithDeliveryCharge =
        modalData.deliveryCharge === 'dhaka' ? Dhaka : Outside;

    const sendMessage = () => {
        if (!Cmessage) {
            toast.error('Write message and send.');
            return;
        }
        myAxios
            .post(`/order-sms/${uid}`, {
                name: modalData.cName,
                mobile: modalData.cMobile,
                message: Cmessage,
            })
            .then((res) => {
                if (res.data.success_message) {
                    toast.success(res.data.success_message);
                }

                if (res.data.error_message) {
                    toast.success(res.data.error_message);
                }

                if (res.data === 'Buy SMS BALANCE') {
                    toast.success('Buy SMS Balance');
                }
                setCmessage('');
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    };

    return (
        <div className="OrderViewModalBackground">
            <ToastContainer />
            <div className="OrderViewModalContainer">
                <div className="OrderViewModalTitleCloseBtn">
                    <button onClick={() => setOpenModal(false)}>X</button>
                </div>
                <div className="OrderViewModalImageQntMainFlex">
                    {modalData.orderData.map((data, index) => {
                        return (
                            <div className="OrderViewModalImgP" key={index}>
                                <img
                                    height={'70px'}
                                    width={'70px'}
                                    src={data.image}
                                    alt="product"
                                />
                                <p>{`Color: ${data.colorName}`}</p>
                                <p>{`Quantity: ${data.quantity}`}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="OrderViewModalTitle">
                    {/* <button
            style={{
              backgroundColor: "#751e1e",
              color: "#ffff",
              padding: "7px",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            Fake Order Report
          </button>
          <p>Fake Report: No</p>

          <div style={{ border: "1px solid #ccc", paddingBottom: "20px" }}>
            <p>এই কাস্টমার কে মেসেজ পাঠাতে নিচে টাইপ করে সেন্ড করুন </p>
            <input
              style={{ outline: "none", padding: "10px" }}
              type="text"
              placeholder="Type your message..."
              value={Cmessage}
              onChange={(e) => setCmessage(e.target.value)}
            />
            <button
              style={{
                outline: "none",
                padding: "10px",
                backgroundColor: "#116243",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={sendMessage}
            >
              Send
            </button>
          </div> */}
                    {modalData.TrafficSource && (
                        <p>Traffic Source: {modalData.TrafficSource}</p>
                    )}
                    <p>
                        Ip Address:{' '}
                        {modalData.City[0]?.query
                            ? modalData.City[0].query
                            : 'No-Ip'}
                    </p>
                    {modalData.PaymentType && (
                        <p>PaymentType: {modalData.PaymentType}</p>
                    )}
                    {modalData.PaymentType == 'BKASH' && (
                        <p>PaymentStatus: Not receive</p>
                    )}
                    {!is_new ? (
                        <p
                            style={{
                                border: '1px solid #ccc',
                                padding: '10px',
                            }}
                        >
                            {`Delivery: Success_rate > ${success_rate}% | Successful_Delivery > ${successful_delivery} | Total_delivery > ${total_delivery}`}
                        </p>
                    ) : (
                        <p
                            style={{
                                border: '1px solid #ccc',
                                padding: '10px',
                            }}
                        >
                            Delivery no order history..
                        </p>
                    )}

                    <p>
                        {modalData.Overseas === 1 ? (
                            <p style={{ backgroundColor: 'wheat' }}>
                                From China:{' '}
                                {modalData.Overseas === 1 ? 'Yes' : 'No'}
                            </p>
                        ) : (
                            ''
                        )}{' '}
                        Order Last 72 Hours:
                        {DuplicateOrder.length && DuplicateOrder.length
                            ? DuplicateOrder.length
                            : 'Expired'}
                    </p>
                    <p>Customer Name: {modalData.cName}</p>
                    <p>Customer Mobile: {modalData.cMobile}</p>
                    <p>Customer Address: {modalData.address}</p>
                    <p>
                        Date:{' '}
                        {Moment(modalData.updateDate).format(
                            'DD MMM Y || h:mm:ss a'
                        )}
                    </p>
                    {modalData.lateDate && (
                        <p>
                            Ship Late Date:{' '}
                            {Moment(modalData.lateDate).format(
                                'DD MMM Y || h:mm:ss a'
                            )}
                        </p>
                    )}
                    <p>Total Item: {modalData.item}</p>
                    <p>Price: {modalData.totalPrice}</p>
                    <p>
                        Include Delivery:{' '}
                        {modalData.totalPrice * 1 + WithDeliveryCharge}
                    </p>
                    <p>Advance: {modalData.advance}</p>
                    <p>Discount: {modalData.discount}</p>
                    <p>Location: {modalData.deliveryCharge}</p>
                    <div>
                        {modalData.product &&
                            modalData.product.map((data) => {
                                return (
                                    <p>
                                        Product
                                        {`: ( ${data.Quality} পিচ ${
                                            data.pCode
                                        } কালার ${data.color} ${
                                            data.Size ? `সাইজ ${data.Size}` : ''
                                        } )`}
                                    </p>
                                );
                            })}
                    </div>
                    {modalData.OrderNote && (
                        <p>Order Note: {modalData.OrderNote}</p>
                    )}
                    {modalData.privateNote && (
                        <p>Private Note: {modalData.privateNote}</p>
                    )}
                </div>

                <div className="OrderViewModalDatePicker">
                    <h4>{`Ship Later Date =>>`}</h4>
                    <div>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                        />
                    </div>
                </div>

                <select
                    name="status"
                    className="OrderViewModalOrderEditStatus"
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                >
                    <option value="">Select Location</option>
                    <option value="dhaka">Dhaka</option>
                    <option value="outside">OutSide</option>
                </select>
                {modalData.orderStatus !== 5 && (
                    <select
                        name="status"
                        className="OrderViewModalOrderEditStatus"
                        onChange={(e) => setStatus(Number(e.target.value))}
                        value={status}
                    >
                        <option value="">Select Status</option>
                        <option value="2">Call Done</option>
                        <option value="7">Call Not-Received</option>
                        <option value="8">Ask-Advance</option>
                        <option value="9">Ship-Later</option>
                        <option value="10">Waiting</option>
                        <option value="11">Overseas</option>
                        <option value="12">Delivery-Issue</option>
                        <option value="13">Payment-Received</option>
                        <option value="3">Courier Done</option>
                        <option value="4">Print Done</option>
                        <option value="5">Cancel</option>
                        <option value="6">Return</option>
                    </select>
                )}
                {status === 6 && (
                    <>
                        <p style={{ textAlign: 'center' }}>কুরিয়ার কি রিটার্ন দিয়েছে Yes বা No সিলেক্ট করুন</p>
                        <select
                            name="status"
                            className="OrderViewModalOrderEditStatus"
                            onChange={(e) => setCourierReturn(e.target.value)}
                            value={CourierReturn}
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                        <p style={{ textAlign: 'center' }}>রিটার্ন টাকার পরিমাণ</p>
                        <input
                            className="OrderViewModalOrderEditStatus"
                            type="number"
                            placeholder="টাকা দিয়ে রিটার্ন করেছে ? এখানে লিখুন. Optional"
                            value={ReturnAmount}
                            onChange={(e) => setReturnAmount(e.target.value)}
                        />
                    </>
                )}

                <div className="OrderViewModalFooter">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                        id="OrderViewModalCancelBtn"
                    >
                        Cancel
                    </button>
                    <button onClick={() => submitH()}>
                        {loading ? 'Loading.' : 'Update'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Modal;
