// Features.jsx
import React from 'react';
import './Features.css';

const featuresData = [
    {
        icon: '⚡',
        title: 'Website',
        points: [
            'Superfast website speed',
            'Free Sub-Domain',
            'Custom Domain',
            'User-friendly Admin Panel',
            'Mobile Optimized',
        ],
    },
    {
        icon: '📈',
        title: 'Unlimited Features',
        points: [
            'Unlimited Orders',
            'Unlimited Products',
            'Unlimited Visitors',
            'Unlimited Landing Pages',
            'Unlimited Team Members',
        ],
    },
    {
        icon: '🚀',
        title: 'Advantages',
        points: [
            'No Coding Required',
            'Area-wise Order Report',
            'Custom Header and Footer',
            'Home Page Customization',
        ],
    },
    {
        icon: '📊',
        title: 'Marketing Tools',
        points: [
            'Facebook Pixel',
            'Conversion API',
            'Google Tag Manager',
            'Google Analytics',
            'SMS Marketing',
        ],
    },
    {
        icon: '💰',
        title: '10x Sales Features',
        points: [
            'Easy Checkout Page',
            'Infinite Scroll',
            'Facebook Order',
            'WhatsApp Order',
            'Phone Order',
        ],
    },
    {
        icon: '🛠️',
        title: 'Extra Features',
        points: [
            'Team Members',
            'Coupons',
            'Invoice & Sticker Print',
            'Hot Deals',
            'Trending Now',
        ],
    },
    {
        icon: '📦',
        title: 'Order Management',
        points: [
            'Most Advanced Order Management in Bangladesh',
            'Team Member Order Assign',
            'Real-time Order Data',
            'One Click Courier Entry',
            'CSV File Download',
        ],
    },
    {
        icon: '🛍️',
        title: 'Product Management',
        points: [
            'Category & Sub-Category',
            'Product Variation with Custom Price',
            'Facebook-like Scroll',
            'Product & Category-wise Offer',
            'Product Video Upload',
        ],
    },
    {
        icon: '📊',
        title: 'Dashboard & Reports',
        points: [
            'Total Sales, Return, and Cancel Data',
            'Profit & Loss Calculation',
            'Daily, Monthly & Custom Reports',
            'Product-wise Sale, Return & Cancel Report',
        ],
    },
    {
        icon: '👥',
        title: 'Customer Management',
        points: ['Customer Delivery Reputation', 'Fraud Customer Block'],
    },
    {
        icon: '💳',
        title: 'Payment Gateway',
        points: ['Bkash', 'Nagad', 'Rocket', 'SSL Commerce'],
    },
    {
        icon: '🚚',
        title: 'Courier Management',
        points: ['Steadfast', 'Pathao', 'Redx'],
    },
];

const Features = () => {
    return (
        <div className="features-container">
            <h1 className="features-heading">Features</h1>
            <div className="features-grid">
                {featuresData.map((feature, index) => (
                    <div className="features-card" key={index}>
                        <div className="features-card-icon">{feature.icon}</div>
                        <div className="features-card-title">
                            {feature.title}
                        </div>
                        <div className="features-card-text">
                            <ul className="features-card-list">
                                {feature.points.map((point, idx) => (
                                    <li
                                        className="features-card-list-item"
                                        key={idx}
                                    >
                                        {point}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Features;
