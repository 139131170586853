// HelperFunction.js
import axios from 'axios';
import UploadImageCdn from '../Helper/UploadImageCdn'; // Adjust the path as needed

// A generic onChange helper that clears errors for a field
export const handleFieldChange =
    (field, setter, errorFields, setErrorFields) => (e) => {
        setter(e.target.value);
        if (errorFields[field]) {
            setErrorFields((prev) => {
                const newErrors = { ...prev };
                delete newErrors[field];
                return newErrors;
            });
        }
    };

// Upload callback for the DraftJS editor
export const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.onloadend = async () => {
            const form_data = new FormData();
            form_data.append('photo', file);
            try {
                const res = await UploadImageCdn(form_data);
                resolve({ data: { link: res } });
            } catch (error) {
                reject(error);
            }
        };
        reader.readAsDataURL(file);
    });
};

// Upload an image to the CDN
export const ImageUpload = async (imgFile) => {
    const image = new FormData();
    image.append('photo', imgFile);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            token: 'zesrxdyctuutydrszrestdrxy',
        },
    };
    const url = 'https://cdn.ecombd.org/upload';
    const res = await axios.post(url, image, config);
    return res.data;
};

// Create a new variation row for "no variation" or "color" variation
export function addRowHelper(rows, variationColorN) {
    const newRow = {
        colorName: variationColorN,
        price: '',
        buy_price: '',
        quantity: '',
        ShipFrom: '',
        DeliveryTime: '',
        PriceCompare: '',
        image: null,
        gid: Math.floor(Math.random() * 9000000976),
    };
    return [...rows, newRow];
}

// Handle change for a row field (for "color" variation)
export function handleInputChangeHelper(rows, event, index) {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = [
        'price',
        'buy_price',
        'quantity',
        'PriceCompare',
    ].includes(name)
        ? Number(value) || ''
        : value;
    return updatedRows;
}

// Upload an image for a row and update that row’s image
export async function handleImageChangeHelper(
    rows,
    event,
    index,
    token = 'zesrxdyctuutydrszrestdrxy'
) {
    const image = new FormData();
    image.append('photo', event?.target?.files[0]);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            token,
        },
    };
    const url = 'https://cdn.ecombd.org/upload';
    const res = await axios.post(url, image, config);
    const updatedRows = [...rows];
    updatedRows[index].image = res.data;
    return updatedRows;
}

// Create a new row for the "color & size" variation
export function addRowSizeHelper(rowsSize, variationColorN) {
    const newRow = {
        colorName: variationColorN,
        colorImage: '',
        sizes: [
            {
                sizeName: 'S',
                price: '',
                quantity: '',
                gid: Math.floor(Math.random() * 9000000976),
            },
            {
                sizeName: 'L',
                price: '',
                quantity: '',
                gid: Math.floor(Math.random() * 9000000976),
            },
            {
                sizeName: 'M',
                price: '',
                quantity: '',
                gid: Math.floor(Math.random() * 9000000976),
            },
            {
                sizeName: 'XL',
                price: '',
                quantity: '',
                gid: Math.floor(Math.random() * 9000000976),
            },
            {
                sizeName: 'XXL',
                price: '',
                quantity: '',
                gid: Math.floor(Math.random() * 9000000976),
            },
        ],
    };
    return [...rowsSize, newRow];
}

// Handle change for a size input in the "color & size" variation
export function handleInputChangeSizeHelper(
    rowsSize,
    event,
    colorIndex,
    sizeIndex
) {
    const { name, value } = event.target;
    const updatedRowsSize = [...rowsSize];
    updatedRowsSize[colorIndex].sizes[sizeIndex][name] = [
        'price',
        'quantity',
    ].includes(name)
        ? Number(value) || ''
        : value;
    return updatedRowsSize;
}

// Upload an image for a color in the "color & size" variation
export async function handleImageChangeSizeHelper(
    rowsSize,
    event,
    colorIndex,
    token = 'zesrxdyctuutydrszrestdrxy'
) {
    const image = new FormData();
    image.append('photo', event?.target?.files[0]);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            token,
        },
    };
    const url = 'https://cdn.ecombd.org/upload';
    const res = await axios.post(url, image, config);
    const newRows = [...rowsSize];
    newRows[colorIndex].colorImage = res.data;
    return newRows;
}

// Remove a size from a "color & size" variation row
export function handleRemoveSizeHelper(rowsSize, colorIndex, sizeIndex) {
    const newRows = [...rowsSize];
    newRows[colorIndex].sizes.splice(sizeIndex, 1);
    return newRows;
}

// Update the images array by setting or adding a new image
export async function handleImageSetWrapperHelper(images, e, imageId) {
    const file = e.target.files[0];
    if (!file) return images;
    const imgUrl = await ImageUpload(file);
    if (imageId) {
        return images.map((img) =>
            img.id === imageId ? { ...img, url: imgUrl } : img
        );
    } else {
        return [...images, { id: Date.now(), url: imgUrl }];
    }
}
