import { useState, useEffect } from 'react';
import './AddProduct.css';
import myAxios from '../AxiosUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MainImageSection from './MainImageSection';
import NoImage from '../../img/noinage.png';
import { useNavigate } from 'react-router-dom';

import {
    handleFieldChange,
    uploadCallback,
    ImageUpload,
    addRowHelper,
    handleInputChangeHelper,
    handleImageChangeHelper,
    addRowSizeHelper,
    handleInputChangeSizeHelper,
    handleImageChangeSizeHelper,
    handleRemoveSizeHelper,
    handleImageSetWrapperHelper,
} from './HelperFunction';

function AddProduct() {
    const [refresh, forceUpdate] = useState(false);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const navigate = useNavigate();
    const [productName, setProductName] = useState('');
    const [offerMessage, setOfferMessage] = useState('');
    const [video, setVideo] = useState('');
    const [HtmlDesc, setHtmlDesc] = useState('');
    const [buyPrice, setbuyPrice] = useState('');
    const [sellingPrice, setsellingPrice] = useState('');
    const [compare, setCompare] = useState('');
    const [pCode, setpCode] = useState('');
    const [colorName, setcolorName] = useState('');
    const [quantity, setquantity] = useState('');

    const [category, setCategory] = useState('');
    const [selectCategory, setSelectCategory] = useState('');
    const [selectva, setelectva] = useState('no');

    const [categorydata, setCategoryData] = useState('');
    const [SubCategoryData, setSubCategoryData] = useState();
    const [SubCategoryId, setSubCategoryId] = useState('');

    const [loading, setLoading] = useState(false);
    const [variationColorN, setVariationColorN] = useState('');
    const [checkvariation, setCheckvariation] = useState('no');
    const [productPage, setProductPage] = useState(1);
    const uid = localStorage.getItem('id');
    const [rows, setRows] = useState([]);
    const [rowsSize, setRowsSize] = useState([]);
    const [Trending, setTrendingNow] = useState('0');
    const [HotDeal, setHotDeal] = useState('0');
    const [showOrderNow, setshowOrderNow] = useState('Yes');
    const [RelatedProduct, setRelatedProduct] = useState('Same');
    const [images, setImages] = useState([]);
    const [errorFields, setErrorFields] = useState({});

    const handleEditorChange = (editorState) => {
        setEditorState(editorState);
        const contentState = editorState.getCurrentContent();
        setHtmlDesc(stateToHTML(contentState));
        if (errorFields.HtmlDesc) {
            setErrorFields((prev) => {
                const newErrors = { ...prev };
                delete newErrors.HtmlDesc;
                return newErrors;
            });
        }
    };

    const validateFields = () => {
        let errors = {};
        if (!productName) errors.productName = true;
        if (!buyPrice) errors.buyPrice = true;
        if (!sellingPrice) errors.sellingPrice = true;
        if (!HtmlDesc) errors.HtmlDesc = true;
        if (!categorydata) errors.categorydata = true;
        if (!images[0]?.url) errors.image = true;
        if (!pCode) errors.pCode = true;
        if (checkvariation === 'no') {
            if (!colorName) errors.colorName = true;
            if (!quantity) errors.quantity = true;
        }
        if (selectva === 'color' && !rows.length) {
            errors.variation = true;
        } else if (selectva === 'colorSize' && !rowsSize.length) {
            errors.variation = true;
        }
        setErrorFields(errors);
        return Object.keys(errors).length === 0;
    };

    const config = {
        image: { uploadCallback: uploadCallback },
    };

    const removeImage = (id) => {
        setImages((prevImages) => prevImages.filter((img) => img.id !== id));
    };

    const getCategory = () => {
        myAxios
            .get(`/category/${uid}`)
            .then((res) => {
                forceUpdate(true);
                setCategory(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getCategory();
        if (category) {
            setSelectCategory([
                { _id: '', name: 'ক্যাটাগরি সিলেক্ট করুন' },
                ...category,
            ]);
        }
    }, [refresh]);

    function addRow() {
        setRows(addRowHelper(rows, variationColorN));
        setVariationColorN('');
    }

    function handleInputChange(event, index) {
        setRows(handleInputChangeHelper(rows, event, index));
    }

    async function handleImageChange(event, index) {
        try {
            const updatedRows = await handleImageChangeHelper(
                rows,
                event,
                index
            );
            toast.success('Image uploaded to CDN');
            setRows(updatedRows);
        } catch (error) {
            console.log(error);
        }
    }

    const addRowSize = () => {
        setRowsSize(addRowSizeHelper(rowsSize, variationColorN));
        setVariationColorN('');
    };

    function handleInputChangeSize(event, colorIndex, sizeIndex) {
        setRowsSize(
            handleInputChangeSizeHelper(rowsSize, event, colorIndex, sizeIndex)
        );
    }

    const handleImageChangeSize = async (event, colorIndex) => {
        try {
            const newRows = await handleImageChangeSizeHelper(
                rowsSize,
                event,
                colorIndex
            );
            toast.success('Image uploaded to CDN');
            setRowsSize(newRows);
        } catch (error) {
            console.log(error);
        }
    };

    function handleRemoveSize(colorIndex, sizeIndex) {
        setRowsSize(handleRemoveSizeHelper(rowsSize, colorIndex, sizeIndex));
    }

    const handleImageSetWrapper = async (e, imageId) => {
        try {
            const updatedImages = await handleImageSetWrapperHelper(
                images,
                e,
                imageId
            );
            setImages(updatedImages);
            toast.success('Image uploaded');
        } catch (error) {
            console.log(error);
        }
    };

    const HandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!validateFields()) {
            toast.error('All required fields must be filled.');
            setLoading(false);
            return;
        }
        const baseData = {
            productName,
            productDesc: HtmlDesc,
            offerMessage,
            video,
            buyPrice,
            sellingPrice,
            images,
            showOrderNow,
            HotDeal,
            Trending,
            RelatedProduct,
            category: categorydata,
            SubCategoryId,
            uid,
            pCode,
            compare,
        };
        const extraData =
            selectva === 'no'
                ? {
                      colorName,
                      quantity,
                      gid: Math.floor(Math.random() * 9000000976),
                  }
                : selectva === 'color'
                ? { variationData: rows, productPage }
                : selectva === 'colorSize'
                ? { variationData: rowsSize }
                : {};
        const formData = { ...baseData, variation: selectva, ...extraData };
        try {
            const res = await myAxios.post('add-product', formData);
            navigate('/product', { replace: true });
            setLoading(false);
            toast.success(res.data.message);
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    };

    function handleRemove(index) {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    }

    function handleRemoveColorSize(index) {
        const updatedRows = [...rowsSize];
        updatedRows.splice(index, 1);
        setRowsSize(updatedRows);
    }

    return (
        <div style={{ width: '100%' }}>
            <ToastContainer />
            <div className="AddProduct-Video">
                <div>
                    How does it work ➡️{' '}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        Video Link
                    </a>{' '}
                </div>
            </div>
            <div className="AddProduct-container">
                <div className="AddProduct-subcontainer">
                    <h3>ADD PRODUCT</h3>
                    <input
                        onChange={handleFieldChange(
                            'productName',
                            setProductName,
                            errorFields,
                            setErrorFields
                        )}
                        type="text"
                        className={`form__input_add ${
                            errorFields.productName ? 'error-field' : ''
                        }`}
                        placeholder="Product Title"
                        value={productName}
                    />
                    <div style={{ margin: '10px' }}>
                        <div
                            style={{
                                backgroundColor: 'rgb(255, 226, 226)',
                                border: `${
                                    errorFields.HtmlDesc
                                        ? '3px solid red'
                                        : '3px solid #fff'
                                }`,
                            }}
                        >
                            <Editor
                                editorState={editorState}
                                placeholder="Write product description here"
                                onEditorStateChange={handleEditorChange}
                                toolbar={config}
                            />
                        </div>
                    </div>
                    <input
                        onChange={handleFieldChange(
                            'video',
                            setVideo,
                            errorFields,
                            setErrorFields
                        )}
                        type="text"
                        className="form__input_add"
                        placeholder="YouTube Video Link - Optional"
                        value={video}
                    />
                    <input
                        onChange={handleFieldChange(
                            'offerMessage',
                            setOfferMessage,
                            errorFields,
                            setErrorFields
                        )}
                        type="text"
                        className="form__input_add"
                        placeholder="OFFER MESSAGE LIKE: ১০০০ টাকার অর্ডারের উপরে ফ্রি ডেলিভারি"
                        value={offerMessage}
                    />
                    <input
                        onChange={handleFieldChange(
                            'buyPrice',
                            setbuyPrice,
                            errorFields,
                            setErrorFields
                        )}
                        type="number"
                        className={`form__input_add ${
                            errorFields.buyPrice ? 'error-field' : ''
                        }`}
                        placeholder="Buying Price - কেনা দাম"
                        value={buyPrice}
                    />
                    <input
                        onChange={handleFieldChange(
                            'sellingPrice',
                            setsellingPrice,
                            errorFields,
                            setErrorFields
                        )}
                        type="number"
                        className={`form__input_add ${
                            errorFields.sellingPrice ? 'error-field' : ''
                        }`}
                        placeholder="Selling Price - বিক্রি দাম"
                        value={sellingPrice}
                    />
                    <input
                        onChange={handleFieldChange(
                            'compare',
                            setCompare,
                            errorFields,
                            setErrorFields
                        )}
                        type="number"
                        className="form__input_add"
                        placeholder="Compare Price"
                        value={compare}
                    />
                    <input
                        onChange={handleFieldChange(
                            'pCode',
                            setpCode,
                            errorFields,
                            setErrorFields
                        )}
                        type="text"
                        className={`form__input_add ${
                            errorFields.pCode ? 'error-field' : ''
                        }`}
                        placeholder="Product Code CDR-001"
                        value={pCode}
                    />
                    <label>Select Variation Type</label>
                    <select
                        name="cars"
                        className="AddProduct-category"
                        onChange={(e) => {
                            setelectva(e.target.value);
                            if (e.target.value === 'color') {
                                setCheckvariation('color');
                            } else if (e.target.value === 'colorSize') {
                                setCheckvariation('colorSize');
                            } else {
                                setCheckvariation('no');
                            }
                        }}
                    >
                        <option value="no">No Variation</option>
                        <option value="color">Color</option>
                        <option value="colorSize">Color & Size</option>
                    </select>

                    {checkvariation === 'color' && (
                        <div className="AddProduct-variation">
                            <input
                                type="text"
                                className={`AddProduct-variation-input ${
                                    errorFields.variationColorN
                                        ? 'error-field'
                                        : ''
                                }`}
                                id="name"
                                placeholder="ColorName"
                                required=""
                                value={variationColorN}
                                onChange={handleFieldChange(
                                    'variationColorN',
                                    setVariationColorN,
                                    errorFields,
                                    setErrorFields
                                )}
                            />
                            <button
                                className="AddProduct-variation-button"
                                onClick={() => {
                                    if (!variationColorN) {
                                        toast.error('কালারের নাম লিখুন..');
                                    } else {
                                        addRow();
                                    }
                                }}
                            >
                                Create Color
                            </button>
                        </div>
                    )}

                    {checkvariation === 'colorSize' && (
                        <div className="AddProduct-variation">
                            <input
                                type="text"
                                className={`AddProduct-variation-input ${
                                    errorFields.variationColorN
                                        ? 'error-field'
                                        : ''
                                }`}
                                id="name"
                                placeholder="ColorName"
                                required=""
                                value={variationColorN}
                                onChange={handleFieldChange(
                                    'variationColorN',
                                    setVariationColorN,
                                    errorFields,
                                    setErrorFields
                                )}
                            />
                            <button
                                className="AddProduct-variation-button"
                                onClick={() => {
                                    if (!variationColorN) {
                                        toast.error('কালারের নাম লিখুন..');
                                    } else {
                                        addRowSize();
                                    }
                                }}
                            >
                                Create Color
                            </button>
                        </div>
                    )}

                    <div className="AddProduct-display-variation">
                        {rows.map((row, index) => (
                            <div
                                className="AddProduct-display-variation-item"
                                key={index}
                            >
                                <img
                                    style={{ marginRight: '5px' }}
                                    src={row.image ? row.image : NoImage}
                                    height={65}
                                    width={65}
                                    alt="Product Variation"
                                />
                                <h2 className="AddProduct-variation-item-title">
                                    {row.colorName}
                                </h2>
                                <input
                                    type="text"
                                    className="AddProduct-variation-input-price"
                                    name="price"
                                    placeholder="Price"
                                    value={row.price}
                                    onChange={(event) =>
                                        handleInputChange(event, index)
                                    }
                                    required=""
                                />
                                <input
                                    type="text"
                                    className="AddProduct-variation-input-compare"
                                    name="PriceCompare"
                                    placeholder="Compare Price"
                                    value={row.PriceCompare}
                                    onChange={(event) =>
                                        handleInputChange(event, index)
                                    }
                                    required=""
                                />
                                <input
                                    type="text"
                                    className="AddProduct-variation-input-buy"
                                    name="buy_price"
                                    placeholder="Buying Price"
                                    value={row.buy_price}
                                    onChange={(event) =>
                                        handleInputChange(event, index)
                                    }
                                    required=""
                                />
                                <input
                                    type="text"
                                    className="AddProduct-variation-input-quantity"
                                    name="quantity"
                                    placeholder="Quantity"
                                    value={row.quantity}
                                    onChange={(event) =>
                                        handleInputChange(event, index)
                                    }
                                    required=""
                                />
                                <input
                                    type="text"
                                    className="AddProduct-variation-input-ship"
                                    name="ShipFrom"
                                    placeholder="ShipFrom"
                                    value={row.ShipFrom}
                                    onChange={(event) =>
                                        handleInputChange(event, index)
                                    }
                                    required=""
                                />
                                <input
                                    type="text"
                                    className="AddProduct-variation-input-time"
                                    name="DeliveryTime"
                                    placeholder="DeliveryTime"
                                    value={row.DeliveryTime}
                                    onChange={(event) =>
                                        handleInputChange(event, index)
                                    }
                                    required=""
                                />
                                <input
                                    type="file"
                                    className="AddProduct-variation-file"
                                    name="photo"
                                    onChange={(event) =>
                                        handleImageChange(event, index)
                                    }
                                />
                                <button
                                    className="AddProduct-variation-remove"
                                    onClick={() => handleRemove(index)}
                                >
                                    X
                                </button>
                            </div>
                        ))}
                    </div>

                    {checkvariation === 'color' && (
                        <select
                            name="productPage"
                            className="AddProduct-category"
                            onChange={handleFieldChange(
                                'productPage',
                                setProductPage,
                                errorFields,
                                setErrorFields
                            )}
                        >
                            <option value="1">
                                Scroll-View-Like-FaceBook No
                            </option>
                            <option value="2">
                                Scroll-View-Like-FaceBook Yes
                            </option>
                        </select>
                    )}

                    {rowsSize.map((row, colorIndex) => (
                        <div
                            className="AddProduct-ColorSizeMain"
                            key={colorIndex}
                        >
                            <img
                                src={row.colorImage ? row.colorImage : NoImage}
                                height={150}
                                width={150}
                                alt="Color Size Variation"
                            />
                            <input
                                type="file"
                                className="AddProduct-file-var"
                                name="photo"
                                onChange={(event) =>
                                    handleImageChangeSize(event, colorIndex)
                                }
                            />
                            <div
                                className="AddProduct-remove-color-size"
                                onClick={handleRemoveColorSize}
                            >
                                X
                            </div>
                            <div className="AddProduct-color-Size-Title">
                                {row.colorName}
                            </div>
                            {row.sizes.map((size, sizeIndex) => (
                                <div
                                    className="AddProduct-SizeName-input"
                                    key={sizeIndex}
                                >
                                    <div>
                                        <input
                                            type="text"
                                            className="AddProduct-size-input"
                                            name="sizeName"
                                            placeholder="sizeName"
                                            value={size.sizeName}
                                            onChange={(event) =>
                                                handleInputChangeSize(
                                                    event,
                                                    colorIndex,
                                                    sizeIndex
                                                )
                                            }
                                            required=""
                                        />
                                        <input
                                            type="text"
                                            className="AddProduct-size-input"
                                            name="price"
                                            placeholder="Price"
                                            value={size.price}
                                            onChange={(event) =>
                                                handleInputChangeSize(
                                                    event,
                                                    colorIndex,
                                                    sizeIndex
                                                )
                                            }
                                            required=""
                                        />
                                        <input
                                            type="text"
                                            className="AddProduct-size-input"
                                            name="quantity"
                                            placeholder="Quantity"
                                            value={size.quantity}
                                            onChange={(event) =>
                                                handleInputChangeSize(
                                                    event,
                                                    colorIndex,
                                                    sizeIndex
                                                )
                                            }
                                            required=""
                                        />
                                        <button
                                            className="AddProduct-var-item-remove"
                                            onClick={() =>
                                                handleRemoveSize(
                                                    colorIndex,
                                                    sizeIndex
                                                )
                                            }
                                        >
                                            X
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button
                                style={{
                                    background: 'green',
                                    padding: '5px',
                                    color: 'white',
                                    border: '1px solid',
                                    marginTop: '10px',
                                    borderRadius: '5px',
                                    width: '100px',
                                }}
                                onClick={() => {
                                    const newSize = {
                                        sizeName: '',
                                        price: '',
                                        quantity: '',
                                        gid: Math.floor(
                                            Math.random() * 9000000976
                                        ),
                                    };
                                    const updatedRows = [...rowsSize];
                                    updatedRows[colorIndex].sizes.push(newSize);
                                    setRowsSize(updatedRows);
                                }}
                            >
                                Add Size
                            </button>
                        </div>
                    ))}

                    {checkvariation === 'no' && (
                        <>
                            <input
                                onChange={handleFieldChange(
                                    'colorName',
                                    setcolorName,
                                    errorFields,
                                    setErrorFields
                                )}
                                type="text"
                                className={`form__input_add ${
                                    errorFields.colorName ? 'error-field' : ''
                                }`}
                                placeholder="প্রোডাক্টের শর্ট নাম"
                                required=""
                                value={colorName}
                            />
                            <input
                                onChange={handleFieldChange(
                                    'quantity',
                                    setquantity,
                                    errorFields,
                                    setErrorFields
                                )}
                                type="number"
                                className={`form__input_add ${
                                    errorFields.quantity ? 'error-field' : ''
                                }`}
                                placeholder="Quantity"
                                required=""
                                value={quantity}
                            />
                        </>
                    )}

                    {/* SELECT CATEGORY */}
                    <select
                        name="category"
                        className={`AddProduct-category ${
                            errorFields.categorydata ? 'error-field' : ''
                        }`}
                        onChange={(e) => {
                            const value = e.target.value;
                            const GetSubCategory = category.find(
                                (cat) => cat._id === value
                            );
                            setSubCategoryData(GetSubCategory.subcategories);
                            setCategoryData(value);
                            if (errorFields.categorydata) {
                                setErrorFields((prev) => {
                                    const newErrors = { ...prev };
                                    delete newErrors.categorydata;
                                    return newErrors;
                                });
                            }
                        }}
                    >
                        {selectCategory &&
                            selectCategory.map((data) => (
                                <option
                                    value={data._id}
                                    key={data._id}
                                    defaultValue={data._id}
                                >
                                    {data.name}
                                </option>
                            ))}
                    </select>
                    {/* SELECT SUB CATEGORY */}
                    {SubCategoryData && SubCategoryData.length > 0 && (
                        <select
                            name="category"
                            className="AddProduct-category"
                            onChange={handleFieldChange(
                                'SubCategoryId',
                                setSubCategoryId,
                                errorFields,
                                setErrorFields
                            )}
                        >
                            <option value="">
                                সাব ক্যাটাগরি সিলেক্ট করুন - অপশনাল
                            </option>
                            {SubCategoryData &&
                                SubCategoryData.map((data) => (
                                    <option value={data._id} key={data._id}>
                                        {data.name}
                                    </option>
                                ))}
                        </select>
                    )}

                    <label>অর্ডার নাউ বাটন শো করতে চান ?</label>
                    <select
                        name="showOrderNow"
                        className="AddProduct-category"
                        value={showOrderNow}
                        onChange={handleFieldChange(
                            'showOrderNow',
                            setshowOrderNow,
                            errorFields,
                            setErrorFields
                        )}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>

                    <label>রিলেটেড প্রোডাক্ট কি হবে সিলেক্ট করুন</label>
                    <select
                        name="RelatedProduct"
                        className="AddProduct-category"
                        value={RelatedProduct}
                        onChange={handleFieldChange(
                            'RelatedProduct',
                            setRelatedProduct,
                            errorFields,
                            setErrorFields
                        )}
                    >
                        <option value="Same">Same Category</option>
                        <option value="All">All Product</option>
                    </select>

                    <label>ট্রেন্ডিং নাউ</label>
                    <select
                        name="RelatedProduct"
                        className="AddProduct-category"
                        value={Trending}
                        onChange={handleFieldChange(
                            'Trending',
                            setTrendingNow,
                            errorFields,
                            setErrorFields
                        )}
                    >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>

                    <label>হট ডিল</label>
                    <select
                        name="RelatedProduct"
                        className="AddProduct-category"
                        value={HotDeal}
                        onChange={handleFieldChange(
                            'HotDeal',
                            setHotDeal,
                            errorFields,
                            setErrorFields
                        )}
                    >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>

                    <MainImageSection
                        images={images}
                        onImageSet={handleImageSetWrapper}
                        onRemoveImage={removeImage}
                        error={errorFields.image}
                    />
                    <button
                        onClick={HandleSubmit}
                        className="AddProduct-submit-button"
                    >
                        {loading ? 'Loading..' : 'Add Product'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddProduct;
