import React, { useState } from 'react';
import { ChangeLandingTwoCardModal } from '../../LandingPageModals/LandingPageModals';

const Pricing = ({ data, setData }) => {
    const [openCard, setOpenCard] = useState(false);
    return (
        <div className="pricing">
            <div className="container">
                <div className="pricing__container">
                    <button
                        className="landing-page-edit-btn"
                        onClick={() => setOpenCard(true)}
                    >
                        প্যাকেজে আপডেট করুন
                    </button>
                    <h1 className="pricing__container__title">Pricing</h1>
                    <div className="pricing__cards__container">
                        {data.courses.length > 0 &&
                            data.courses.map((card) => (
                                <PricingCard key={card.id} card={card} />
                            ))}
                    </div>
                </div>
            </div>
            <ChangeLandingTwoCardModal
                openModal={openCard}
                setOpenModal={setOpenCard}
                data={data}
                setData={setData}
            />
        </div>
    );
};

const PricingCard = ({ card }) => {
    return (
        <div className="pricing__card__container">
            <h3>{card.title}</h3>
            <h1>{card.price}</h1>
            <h3>{card.delivery_charge}</h3>
        </div>
    );
};

export default Pricing;
