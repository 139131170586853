import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './OrderList.css';
import myAxios from '../AxiosUrl';
import OrderViewModal from '../OrderViewModal/OrderViewModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PrintModal from '../PrintModal/PrintModal';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import AssignModal from '../AssignModal/AssignModal';
import ChangeStatusModal from '../ChangeStatusModal/ChangeStatusModal';
import SortDay from './SortDay/SortDay';
import DatePickerFilter from './DatePickerFilter/DatePickerFilter';
import OTPFilter from './OTPFilter/OTPFilter';
import HowManyOrderWillShow from './HowManyOrderWillShow/HowManyOrderWillShow';
import OrderFilterSortSearch from './OrderFilterSortSearch/OrderFilterSortSearch';
import SelectedItemSection from './selectedItemSection/selectedItemSection';
import OrderTable from './OrderTable/OrderTable';
import { downloadCSV } from '../Helper/downloadCSV';
import { deleteOrderHelper } from '../Helper/deleteOrder';
import { steadFastEntryHelper } from '../Helper/steadFastEntry';
import { getOrders } from '../Helper/getOrders';
import { CircularProgress } from '@mui/material';

function Order() {
    // Date states
    const [DateFrom, setDateFrom] = useState(new Date());
    const [DateTo, setDateTo] = useState(new Date());
    DateFrom.setHours(0, 0, 0);
    DateTo.setHours(0, 0, 0);

    // Other states
    const [DateSearch, setDateSearch] = useState(false);
    const [RefreshSe, setRefreshSe] = useState(1);
    const uid = localStorage.getItem('id');
    const navigate = useNavigate();
    const [Totalpage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setPageLimit] = useState(10);
    const [OrderCal, setOrderCal] = useState([]);
    const [pData, setPdata] = useState([]);
    const [modalData, setmodalData] = useState([]);
    const [open, setOpenModal] = useState(false);
    const [searchN, setSearchNum] = useState('');
    const [orderStatus, setOrderStatus] = useState(undefined);
    const [refresh, setRefresh] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const [printModalData, setPrintModalData] = useState();
    const [loading, setLoading] = useState(false);
    const [FilterDate, setFilterDate] = useState('Today');
    const [ReceiveReturn, setReceiveReturn] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [SteadFastLoading, setSteadFastLoading] = useState(false);
    const [AssignOpen, setAssignOpen] = useState(false);
    const [ChangeStatusOpen, setChangeStatusOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [Dhaka, setDhaka] = useState();
    const [Outside, setOutside] = useState();

    // Handle pagination change
    const PageNumber = (e, p) => {
        setPage(p);
    };

    // Toggle a single order item
    const handleToggleItem = (itemId) => {
        setSelectedItems((prev) =>
            prev.includes(itemId)
                ? prev.filter((item) => item !== itemId)
                : [...prev, itemId]
        );
    };

    // Toggle all orders
    const handleToggleAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(OrderD);
        }
        setSelectAll(!selectAll);
    };

    // Filter orders based on status and return flag
    const OrderD = pData.filter((fdata) => {
        if (orderStatus === undefined) {
            return fdata;
        } else if (ReceiveReturn) {
            return fdata.CourierReturn === 'no' && fdata.orderStatus === 6;
        } else {
            return fdata.orderStatus === orderStatus;
        }
    });

    // Get orders using helper
    const getData = () => {
        setLoading(true);
        getOrders({
            searchN: searchN || undefined,
            orderStatus: orderStatus !== undefined ? orderStatus : null,
            uid,
            page,
            limit,
            FilterDate,
            DateFrom,
            DateTo,
            DateSearch,
        })
            .then((res) => {
                setPdata(res.data.data);
                setTotalPage(res.data.total);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    // Calculation of order metrics (advance, discount, etc.)
    useEffect(() => {
        let Today_Adv = 0;
        OrderD.forEach((data) => {
            if (data.advance) {
                Today_Adv += Number(data.advance);
            }
        });

        const today = pData.filter((order) => [5].includes(order.orderStatus));
        let TakaTotalDay = 0;
        let TodaySale = 0;
        today.forEach((data) => {
            TakaTotalDay += Number(data.totalPrice);
            data.orderData.forEach((totalquantity) => {
                TodaySale += Number(totalquantity.quantity);
            });
        });

        const todayProfit = pData.filter((order) =>
            [2, 3, 4, 11].includes(order.orderStatus)
        );
        let ProfitToday = 0;
        let DiscountToday = 0;
        todayProfit.forEach((data) => {
            if (data.discount) DiscountToday += Number(data.discount);
            if (data.profit) ProfitToday += Number(data.profit);
        });

        const todayReturn = pData.filter((order) =>
            [6].includes(order.orderStatus)
        );
        let returnAmunt = 0;
        let returnProfit = 0;
        todayReturn.forEach((data) => {
            if (data.deliveryCharge === 'dhaka') {
                const amount = 55;
                if (data.ReturnAmount) {
                    if (Number(data.ReturnAmount) > amount) {
                        returnProfit = Number(data.ReturnAmount) - amount;
                    } else {
                        returnAmunt += amount - Number(data.ReturnAmount);
                    }
                } else {
                    returnAmunt += amount;
                }
            }
            if (data.deliveryCharge === 'outside') {
                const amount = 95;
                if (data.ReturnAmount) {
                    if (Number(data.ReturnAmount) > amount) {
                        returnProfit = Number(data.ReturnAmount) - amount;
                    } else {
                        returnAmunt += amount - Number(data.ReturnAmount);
                    }
                } else {
                    returnAmunt += amount;
                }
            }
        });

        const OrderDataCal = {
            advance: Today_Adv,
            discount: DiscountToday,
            sale: TodaySale,
            taka: TakaTotalDay,
            Profit: ProfitToday - returnAmunt + returnProfit,
            Return: todayReturn.length,
        };

        setOrderCal(OrderDataCal);
    }, [RefreshSe, refresh, pData]);

    // Delete alert using helper
    const AlartSubmit = (id) => {
        setRefresh(false);
        confirmAlert({
            title: 'DELETE CONFIRMATION',
            message: 'Are you sure to delete the product.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteOrderHelper(id, setRefresh);
                        setPdata((prevData) =>
                            prevData.filter((order) => order._id !== id)
                        );
                    },
                },
                {
                    label: 'No',
                    onClick: () => null,
                },
            ],
        });
    };

    // Trigger SteadFastEntry using helper
    const SteadFastEntry = () => {
        steadFastEntryHelper(
            uid,
            selectedItems,
            setSteadFastLoading,
            setSelectedItems
        );
    };

    // Get orders on dependency changes
    useEffect(() => {
        getData();
    }, [refresh, page, limit, FilterDate, RefreshSe]);

    // Get domain data
    useEffect(() => {
        myAxios
            .get(`get-domain/${uid}`)
            .then((res) => {
                setDhaka(parseInt(res.data[0].Dhaka));
                setOutside(parseInt(res.data[0].Outside));
            })
            .catch((err) => console.log(err.response.data));
    }, [uid]);

    return (
        <div style={{ maxWidth: '98%', minHeight: '91vh', margin: '0 auto' }}>
            <ToastContainer />
            {AssignOpen && (
                <AssignModal
                    setAssignOpen={setAssignOpen}
                    data={selectedItems}
                    setRefresh={setRefresh}
                    setSelectedItems={setSelectedItems}
                />
            )}
            {ChangeStatusOpen && (
                <ChangeStatusModal
                    setChangeStatusOpen={setChangeStatusOpen}
                    data={selectedItems}
                    setRefresh={setRefresh}
                    setSelectedItems={setSelectedItems}
                />
            )}
            {open && (
                <OrderViewModal
                    modalData={modalData}
                    setOpenModal={setOpenModal}
                    setRefresh={setRefresh}
                    handleUpdate={getData}
                />
            )}
            {openPrint && (
                <PrintModal
                    printModalData={printModalData}
                    setOpenPrint={setOpenPrint}
                    setRefresh={setRefresh}
                />
            )}
            <section className="section-order">
                <div className="filter-2nd-order-list">
                    <SortDay setFilterDate={setFilterDate} />
                    <DatePickerFilter
                        DateFrom={DateFrom}
                        setDateFrom={setDateFrom}
                        DateTo={DateTo}
                        setDateTo={setDateTo}
                        setDateSearch={setDateSearch}
                        setRefreshSe={setRefreshSe}
                    />
                    <HowManyOrderWillShow setPageLimit={setPageLimit} />
                </div>

                <OrderFilterSortSearch
                    setOrderStatus={setOrderStatus}
                    setReceiveReturn={setReceiveReturn}
                    setLoading={setLoading}
                    setSearchNum={setSearchNum}
                    searchN={searchN}
                    orderStatus={orderStatus}
                    ReceiveReturn={ReceiveReturn}
                    triggerSearch={getData}
                    setRefreshSe={setRefreshSe}
                />
                <SelectedItemSection
                    selectedItems={selectedItems}
                    setChangeStatusOpen={setChangeStatusOpen}
                    setAssignOpen={setAssignOpen}
                    SteadFastEntry={SteadFastEntry}
                    SteadFastLoading={SteadFastLoading}
                    DownloadCSV={() =>
                        downloadCSV(
                            selectedItems,
                            Dhaka,
                            Outside,
                            setSelectedItems
                        )
                    }
                />
                <OTPFilter OrderD={OrderD} OrderCal={OrderCal} />
                <OrderTable
                    OrderD={OrderD}
                    setmodalData={setmodalData}
                    setOpenModal={setOpenModal}
                    setRefresh={setRefresh}
                    setPrintModalData={setPrintModalData}
                    setOpenPrint={setOpenPrint}
                    selectedItems={selectedItems}
                    handleToggleItem={handleToggleItem}
                    handleToggleAll={handleToggleAll}
                    selectAll={selectAll}
                    navigate={navigate}
                    AlartSubmit={AlartSubmit}
                />
            </section>
            {Totalpage > 0 && (
                <div className="pagination-order-list">
                    <Stack spacing={2}>
                        <Pagination
                            count={Totalpage}
                            color="primary"
                            onChange={PageNumber}
                        />
                    </Stack>
                </div>
            )}
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}

export default Order;
