// Helper/getOrders.js
import myAxios from '../AxiosUrl';

export const getOrders = async (params) => {
    const {
        searchN,
        orderStatus,
        uid,
        page,
        limit,
        FilterDate,
        DateFrom,
        DateTo,
        DateSearch,
    } = params;

    const url = `get-order?searchN=${searchN}&orderStatus=${orderStatus}&uid=${uid}&page=${page}&limit=${limit}&FilterDate=${FilterDate}&DateFrom=${DateFrom}&DateTo=${DateTo}&DateSearch=${DateSearch}`;
    return myAxios.get(url);
};
