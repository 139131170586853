import React, { useState } from 'react';
import VideoPlayer from '../utils/VideoPlayer';
import { ChangeVideoDataModal } from '../../LandingPageModals/LandingPageModals';

const Video = ({ data, setData }) => {
    const [openVideoData, setOpenVideoData] = useState(false);
    return (
        <div className="container">
            <div className="video__container">
                <button
                    className="landing-page-edit-btn"
                    onClick={() => setOpenVideoData(true)}
                    style={{
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: '10px',
                        padding: '8px 16px',
                    }}
                >
                    Set or Update Title Video and Subtitle
                </button>
                <h2>{data.video.mainTitle}</h2>
                <VideoPlayer url={data.video.videoURL} />
                <h2>{data.video.subTitle}</h2>
                <button>এখানে অর্ডার করুন</button>
            </div>
            <ChangeVideoDataModal
                data={data}
                setData={setData}
                openVideoData={openVideoData}
                setOpenVideoData={setOpenVideoData}
            />
        </div>
    );
};

export default Video;
