// downloadCSV.js
import Papa from 'papaparse';

export const downloadCSV = (
    selectedItems,
    Dhaka,
    Outside,
    setSelectedItems
) => {
    const Data = [];
    selectedItems.forEach((order) => {
        let PriceAfterAdvance;
        if (order.advance && order.discount) {
            let ad = parseInt(order.advance) + parseInt(order.discount);
            PriceAfterAdvance = order.totalPrice - ad;
        } else if (order.advance) {
            PriceAfterAdvance = order.totalPrice - parseInt(order.advance);
        } else if (order.discount) {
            PriceAfterAdvance = order.totalPrice - parseInt(order.discount);
        } else {
            PriceAfterAdvance = order.totalPrice;
        }

        const WithDeliveryCharge =
            order.deliveryCharge === 'dhaka' ? Dhaka : Outside;
        const cod = WithDeliveryCharge + parseInt(PriceAfterAdvance);

        const orderTitles = order.orderData
            ? order.orderData.map((item) => item.title).join(' || ')
            : '';

        const pushData = {
            Name: order.cName,
            Mobile: order.cMobile,
            Addres: order.address,
            Amount: cod,
            Product: orderTitles,
        };

        Data.push(pushData);
    });

    const csv = Papa.unparse(Data, {
        header: true,
    });

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (setSelectedItems) {
        setSelectedItems([]);
    }
};
