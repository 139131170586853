import React from 'react';
import Header from './Header';
import Description from './Description';
import Features from './Features';
import Effectiveness from './Effectiveness';
import EffectivenessCards from './EffectivenessCards';
import { EffectivenessContentList } from './EffectivenessContentList';
import Problems from './Problems';
import Decisions from './Decisions';
import { Footer } from './Footer';
import { Questions } from './Questions';
import Variantions from './Variations';
import data from '../Data';

import '../landing-1.css';
import DeliveryCharge from './DeliveryCharge';

const Landing2Home = () => {
    return (
        <div className="landing-page-main-container">
            {/* <Header /> */}
            <Description data={data} />
            <Variantions data={data} />
            <DeliveryCharge data={data} />
            <Features data={data} />
            <Effectiveness data={data} />
            <EffectivenessCards data={data} />
            <EffectivenessContentList data={data} />
            <Problems data={data} />
            <Questions data={data} />
            <Decisions data={data} />
        </div>
    );
};

export default Landing2Home;
