import React from 'react';

function SelectedItemSection({
    selectedItems,
    setChangeStatusOpen,
    setAssignOpen,
    SteadFastEntry,
    SteadFastLoading,
    DownloadCSV,
}) {
    if (selectedItems.length === 0) return null;
    return (
        <div className="when-select">
            <div
                className="when-select-state"
                onClick={() => setChangeStatusOpen(true)}
            >
                Change Status
            </div>
            <div className="when-select-state">Print Invoice</div>
            <div className="when-select-state">Print Sticker</div>
            <div className="when-select-state" onClick={SteadFastEntry}>
                {SteadFastLoading ? 'Loading..' : 'SteadFast Entry'}
            </div>
            <div
                className="when-select-state"
                onClick={() => setAssignOpen(true)}
            >
                Assign Order
            </div>
            <div className="when-select-state" onClick={DownloadCSV}>
                Download CSV
            </div>
            <div
                style={{ backgroundColor: 'rgb(203 31 31)' }}
                className="when-select-state"
            >
                Delete Order
            </div>
        </div>
    );
}

export default SelectedItemSection;
