import React, { useState } from 'react';
import { ChangeReviewImageLandingTwoModal } from '../../LandingPageModals/LandingPageModals';

export const Review = ({ data, setData }) => {
    const [open, setOpen] = useState(false);
    const [updatedImage, setUpdatedImage] = useState(null);
    return (
        <div className="review">
            <div className="container">
                <div className="review__container">
                    <button
                        className="landing-page-edit-btn"
                        onClick={() => setOpen(true)}
                        style={{ marginBottom: '20px' }}
                    >
                        রিভিউ ইমেজ সেট করুন অথবা আপডেট করুন
                    </button>
                    <div className="review__title">
                        <h2>
                            আমাদের সম্মানিত কাস্টমারের অনেক রিভিউ থেকে কিছু
                            রিভিউ এখানে দেয়া হলঃ
                        </h2>
                        <img
                            src={data.ReviewImage}
                            alt="review__image"
                            style={{ height: '455px' }}
                        />
                    </div>
                </div>
            </div>
            <ChangeReviewImageLandingTwoModal
                data={data}
                setData={setData}
                open={open}
                setOpen={setOpen}
                updatedImage={updatedImage}
                setUpdatedImage={setUpdatedImage}
            />
        </div>
    );
};
