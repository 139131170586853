import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LandingPage.css';

import Landing1 from '../../img/landing1.png';
import Landing2 from '../../img/landing2.png';

const LandingPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="landing-page-container">
                {/* <div className="landing-page-box">
          <h2>Landing Page One</h2>
          <div className="landing-page-buttons">
            <button
              onClick={() => navigate("/landing1/1")}
              className="landing-page-preview-button"
            >
              Preview
            </button>
            <button
              className="landing-page-select-button"
              onClick={() => navigate("/landing1/edit/" + 1)}
            >
              Select
            </button>
          </div>
          <div className="landing-page-image">
            <img src={Landing1} alt="Preview 1" />
          </div>
        </div> */}

                <div className="landing-page-box">
                    <div className="landing-page-buttons">
                        {/* <button
              onClick={() => navigate("/landing/1")}
              className="landing-page-preview-button"
            >
              Preview
            </button> */}
                        <button
                            className="landing-page-select-button"
                            onClick={() => navigate('/landing/edit/1')}
                        >
                            Create Landing Page
                        </button>
                    </div>
                    <div className="landing-page-image">
                        <img src={Landing2} alt="Preview 2" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LandingPage;
