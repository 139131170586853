import React from 'react';
import { FaFileAlt, FaSearch } from 'react-icons/fa';

function OrderFilterSortSearch({
    setOrderStatus,
    setReceiveReturn,
    setLoading,
    setSearchNum,
    searchN,
    orderStatus,
    ReceiveReturn,
    triggerSearch,
    setRefreshSe,
}) {
    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!searchN) return;
        setLoading(true);
        triggerSearch();
    };

    // Update search state
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchNum(value);
        if (value === '') {
            setRefreshSe((prev) => prev + 1);
        }
    };

    // Navigate to report
    const navigateToReport = () => {
        window.location.href = '/advance-report';
    };

    return (
        <div className="order-filter-main">
            <div className="main-filter-status">
                <ul className="filter-list">
                    <li
                        className={`filter-item ${!orderStatus ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus();
                            setReceiveReturn(false);
                        }}
                    >
                        All-Order
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 1 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(1);
                            setReceiveReturn(false);
                        }}
                    >
                        New
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 2 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(2);
                            setReceiveReturn(false);
                        }}
                    >
                        Call-Done
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 7 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(7);
                            setReceiveReturn(false);
                        }}
                    >
                        Call Not-Receiced
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 8 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(8);
                            setReceiveReturn(false);
                        }}
                    >
                        Ask-Advance
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 9 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(9);
                            setReceiveReturn(false);
                        }}
                    >
                        Ship-Later
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 3 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(3);
                            setReceiveReturn(false);
                        }}
                    >
                        Courier-Done
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 10 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(10);
                            setReceiveReturn(false);
                        }}
                    >
                        Waiting
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 4 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(4);
                            setReceiveReturn(false);
                        }}
                    >
                        Print
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 5 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(5);
                            setReceiveReturn(false);
                        }}
                    >
                        Cancel
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 6 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(6);
                            setReceiveReturn(false);
                        }}
                    >
                        Return
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 11 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(11);
                            setReceiveReturn(false);
                        }}
                    >
                        Overseas
                    </li>
                    <li
                        className={`filter-item ${ReceiveReturn === true ? 'active' : ''}`}
                        onClick={() => {
                            setReceiveReturn(true);
                            setOrderStatus(100);
                        }}
                    >
                        Pending-Return
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 12 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(12);
                            setReceiveReturn(false);
                        }}
                    >
                        Delivery-Issue
                    </li>
                    <li
                        className={`filter-item ${orderStatus === 13 ? 'active' : ''}`}
                        onClick={() => {
                            setOrderStatus(13);
                            setReceiveReturn(false);
                        }}
                    >
                        Payment-Received
                    </li>
                </ul>
            </div>

            <div className="search-container">
                <form onSubmit={handleSubmit} className="search-form">
                    <button
                        className="advance-report-btn"
                        onClick={navigateToReport}
                        type="button"
                    >
                        <FaFileAlt style={{ marginRight: '5px' }} /> Advance
                        Order Report
                    </button>
                    <input
                        type="text"
                        name="searchInput"
                        placeholder="Search by Mobile, Invoice, or Name"
                        className="search-input"
                        value={searchN}
                        onChange={handleInputChange}
                    />
                    <button type="submit" className="search-button">
                        <FaSearch style={{ marginRight: '5px' }} /> Search
                    </button>
                </form>
            </div>
        </div>
    );
}

export default OrderFilterSortSearch;
