// ImageUploader.js
import React from 'react';
import NoImage from '../../img/noinage.png';

const ImageUploader = ({ images, onImageSet, onRemoveImage, error }) => {
    return (
        <div style={{ width: '100%' }}>
            {/* Image size message */}
            <h2
                style={{
                    textAlign: 'center',
                    color: error ? 'red' : '#403a3a',
                    border: error ? '2px solid red' : '2px solid',
                    padding: '10px',
                    borderRadius: '5px',
                    margin: '10px',
                    Width: '100%',
                    backgroundColor: 'rgb(255, 255, 255)',
                }}
            >
                Image size must be 1000 * 1000 and up to 3Mb
            </h2>

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {images.map((image, index) => (
                    <div
                        key={image.id}
                        style={{
                            margin: '10px',
                            position: 'relative',
                            textAlign: 'center',
                        }}
                    >
                        {index === 0 && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    zIndex: 1,
                                }}
                            >
                                Main Image
                            </div>
                        )}
                        <img
                            src={image.url || NoImage}
                            alt={`Image ${image.id}`}
                            height={150}
                            width={150}
                            style={{
                                border: error ? '2px solid red' : '2px solid',
                                padding: '7px',
                                objectFit: 'cover',
                            }}
                        />
                        {/* Remove button at the top-right */}
                        <button
                            onClick={() => onRemoveImage(image.id)}
                            style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                background: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                            }}
                        >
                            X
                        </button>
                        {/* File input to change image */}
                        <div style={{ marginTop: '5px' }}>
                            <input
                                type="file"
                                onChange={(e) => onImageSet(e, image.id)}
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    width: '30%',
                                }}
                            />
                        </div>
                    </div>
                ))}

                {/* "Add Image" box – only shows if fewer than 5 images exist */}
                {images.length < 5 && (
                    <div
                        style={{
                            margin: '10px',
                            width: '150px',
                            height: '150px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '2px dashed #ccc',
                            cursor: 'pointer',
                            position: 'relative',
                        }}
                    >
                        <label
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            + Add Image
                            <input
                                type="file"
                                onChange={(e) => onImageSet(e, null)} // Passing null indicates a new image.
                                style={{ display: 'none' }}
                            />
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUploader;
