import React, { useState, useEffect } from 'react';

const DeliveryCharge = ({ data, setData }) => {
    const [insideDhaka, setInsideDhaka] = useState(
        data?.DeliveryCharge?.insideDhaka || ''
    );
    const [outsideDhaka, setOutsideDhaka] = useState(
        data?.DeliveryCharge?.outsideDhaka || ''
    );

    useEffect(() => {
        if (data?.DeliveryCharge) {
            setInsideDhaka(data.DeliveryCharge.insideDhaka);
            setOutsideDhaka(data.DeliveryCharge.outsideDhaka);
        }
    }, [data?.DeliveryCharge]);

    const handleDeliveryChargeUpdate = (field, value) => {
        setData((prevData) => ({
            ...prevData,
            DeliveryCharge: {
                ...prevData.DeliveryCharge,
                [field]: value,
            },
        }));
    };

    return (
        <div className="container delivery-container">
            <div className="delivery_title">ডেলিভারি চার্জ</div>
            <div className="delivery_input-group">
                <label htmlFor="insideDhaka">ঢাকার ভিতরে</label>
                <input
                    type="text"
                    id="insideDhaka"
                    name="insideDhaka"
                    placeholder="Enter Delivery Charge"
                    value={insideDhaka}
                    onChange={(e) => {
                        setInsideDhaka(e.target.value);
                        handleDeliveryChargeUpdate(
                            'insideDhaka',
                            e.target.value
                        );
                    }}
                />
            </div>
            <div className="delivery_input-group">
                <label htmlFor="outsideDhaka">ঢাকার বাহিরে</label>
                <input
                    type="text"
                    id="outsideDhaka"
                    name="outsideDhaka"
                    placeholder="Enter Delivery Charge"
                    value={outsideDhaka}
                    onChange={(e) => {
                        setOutsideDhaka(e.target.value);
                        handleDeliveryChargeUpdate(
                            'outsideDhaka',
                            e.target.value
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default DeliveryCharge;
