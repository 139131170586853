import React from 'react';

function HowManyOrderWillShow({ setPageLimit }) {
    return (
        <div className="how-many-order-will-show">
            <label for="ShowOrders">Show Order: </label>
            <select
                name="orderLimit"
                className="orderLimitSelect"
                onChange={(e) => setPageLimit(Number(e.target.value))}
                defaultValue={10}
            >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </select>
        </div>
    );
}

export default HowManyOrderWillShow;
