import React, { useState } from 'react';
import { ChangePageName } from '../../LandingPageModals/LandingPageModals';

const PageName = ({ data, setData }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="description" style={{ backgroundColor: '#fff' }}>
            <div className="container">
                <div className="page-name-container">
                    <div className="page-name-title" style={{ color: '#000' }}>
                        {data?.Name
                            ? `ল্যান্ডিং পেজের নাম: ${data.Name}`
                            : 'আপনার ল্যান্ডিং পেজের নাম সেট করুন'}
                    </div>
                    <button
                        className="landing-page-edit-btn"
                        onClick={() => setOpen(true)}
                    >
                        {data?.Name
                            ? 'EDIT LANDING PAGE NAME'
                            : 'CREATE LANDING PAGE NAME'}
                    </button>
                </div>
            </div>
            <ChangePageName
                open={open}
                setOpen={setOpen}
                data={data}
                setData={setData}
            />
        </div>
    );
};

export default PageName;
