// Helper/deleteOrder.js
import myAxios from '../AxiosUrl';
import { toast } from 'react-toastify';

export const deleteOrderHelper = (id, setRefresh) => {
    const orderDeleteUrl = `delete-order/${id}`;
    myAxios
        .delete(orderDeleteUrl)
        .then((res) => {
            setRefresh(true);
            toast.success('Deleted..');
        })
        .catch((error) => {
            console.log(error);
        });
};
