import axios from 'axios';

let token = localStorage.getItem('token');
let uid = localStorage.getItem('id');

var myAxios = axios.create({
    // baseURL: 'http://localhost:4000',
    baseURL: 'https://api.ecombd.org/',
    headers: { token: token, uid: uid },
});

export default myAxios;
