import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiSearchCircle } from 'react-icons/hi';

function DatePickerFilter({
    DateFrom,
    setDateFrom,
    DateTo,
    setDateTo,
    setDateSearch,
    setRefreshSe,
}) {
    return (

            <div className="date-date-Picker">
                <DatePicker
                    selected={DateFrom}
                    onChange={(date) => setDateFrom(date)}
                />
                <p style={{ paddingRight: '10px' }}>To</p>
                <DatePicker
                    selected={DateTo}
                    onChange={(date) => setDateTo(date)}
                />
                <HiSearchCircle
                    className="search-date"
                    onClick={() => {
                        setDateSearch(true);
                        setRefreshSe((prev) => prev + 1);
                    }}
                />
            </div>

    );
}

export default DatePickerFilter;
