import React from 'react';
function SortDay({ setFilterDate, filterDate }) {
    return (
        <div className="sort-day">
            <select
                name="filterData"
                className="date-filter"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
            >
                <option value="Today">Today</option>
                <option value="Yesterday">Yesterday</option>
                <option value="7 Days">Week</option>
                <option value="Month">Month</option>
                <option value="Last Month">Last Month</option>
                <option value="all">All Orders</option>
            </select>
        </div>
    );
}

export default SortDay;

