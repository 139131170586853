import React from 'react';

function OTPFilter({ OrderD, OrderCal }) {
    return (
        <div
           className='otp-filter'
        >
            <h3 className="otp-filter-text">
                ORDERS: {OrderD.length}
            </h3>
            <h3 className="otp-filter-text">
                TAKA: {OrderCal.taka}
            </h3>
            <h3 className="otp-filter-text">
                PROFIT: {OrderCal.Profit}
            </h3>
        </div>
    );
}

export default OTPFilter;
