import React, { useState } from 'react';
import Video from './Video';
import Faq from './Faq';
import Pricing from './Pricing';
import { Review } from './Review';
import { Form } from './Form';
import DeliveryCharge from './DeliveryCharge';
import myAxios from '../../AxiosUrl';
import Landing1Data from '../Landing1Data';
import { useNavigate } from 'react-router-dom';
import PageName from './PageName';
import { toast, ToastContainer } from 'react-toastify';
import '../landing-2.css';

const EditLanding1Home = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(Landing1Data);
    const [loading, setLoading] = useState(false);
    const uid = localStorage.getItem('id');

    const SaveLanding1 = () => {
        setLoading(true);
        if (!data.Name) {
            toast.error('Please set page name');
            setLoading(false);
            return;
        }
        myAxios
            .post(`create-landing1/${uid}`, data)
            .then((res) => {
                setLoading(false);
                navigate('/landing-list');
            })
            .catch((err) => console.log(err.response.data));
    };

    return (
        <>
            <ToastContainer />
            <div className="landing-page-main-container">
                <PageName data={data} setData={setData} />
                <Video data={data} setData={setData} />
                <Faq data={data} setData={setData} />
                <Pricing data={data} setData={setData} />
                <Review data={data} setData={setData} />
                <DeliveryCharge data={data} setData={setData} />
                <Form data={data} />
            </div>
            <div className="save-landing" onClick={SaveLanding1}>
                {loading ? 'LOADING..' : 'SAVE PAGE'}
            </div>
        </>
    );
};

export default EditLanding1Home;
