import React, { useEffect, useState } from "react";

const FacebookPixelSelectorUnified = () => {
  const APP_ID = "9460984257265347"; // Your Facebook App ID
  const APP_SECRET = "YOUR_APP_SECRET"; // Replace with your App Secret (do not expose in production)

  // State variables
  const [userAccessToken, setUserAccessToken] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);
  const [pixelSource, setPixelSource] = useState("business"); // "business" or "ad_account"
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedAdAccount, setSelectedAdAccount] = useState(null);
  const [businessPixels, setBusinessPixels] = useState([]);
  const [adAccountPixels, setAdAccountPixels] = useState([]);
  const [selectedPixel, setSelectedPixel] = useState(null);
  const [step, setStep] = useState("login"); // steps: "login", "select", "confirmation"
  const [longLivedToken, setLongLivedToken] = useState(null);

  // ---------------------- Load Facebook SDK ----------------------
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: APP_ID,
        cookie: true,
        xfbml: true,
        version: "v18.0",
      });
    };

    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  // ---------------------- Step 1: Login ----------------------
  const handleLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const token = response.authResponse.accessToken;
          setUserAccessToken(token);
          // Fetch both businesses and ad accounts
          fetchBusinesses(token);
          fetchAdAccounts(token);
          setStep("select");
        } else {
          console.error("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "pages_show_list,business_management,ads_management" }
    );
  };

  // ---------------------- Fetch User's Businesses ----------------------
  const fetchBusinesses = async (token) => {
    try {
      const res = await fetch(
        `https://graph.facebook.com/v18.0/me/businesses?access_token=${token}`
      );
      const data = await res.json();
      if (data && data.data) {
        setBusinesses(data.data);
      } else {
        console.error("Unexpected businesses data:", data);
      }
    } catch (error) {
      console.error("Error fetching businesses:", error);
    }
  };

  // ---------------------- Fetch User's Ad Accounts ----------------------
  const fetchAdAccounts = async (token) => {
    try {
      const res = await fetch(
        `https://graph.facebook.com/v18.0/me/adaccounts?access_token=${token}`
      );
      const data = await res.json();
      if (data && data.data) {
        setAdAccounts(data.data);
      } else {
        console.error("Unexpected ad accounts data:", data);
      }
    } catch (error) {
      console.error("Error fetching ad accounts:", error);
    }
  };

  // ---------------------- Fetch Pixels for Business ----------------------
  const fetchBusinessPixels = async (businessId) => {
    try {
      const res = await fetch(
        `https://graph.facebook.com/v18.0/${businessId}/owned_pixels?access_token=${userAccessToken}`
      );
      const data = await res.json();
      if (data && data.data) {
        setBusinessPixels(data.data);
      } else {
        console.error("Unexpected business pixels data:", data);
      }
    } catch (error) {
      console.error("Error fetching business pixels:", error);
    }
  };

  // ---------------------- Fetch Pixels for Ad Account ----------------------
  const fetchAdAccountPixels = async (adAccountId) => {
    try {
      // The ad account id must be prefixed with "act_"
      const res = await fetch(
        `https://graph.facebook.com/v18.0/act_${adAccountId}/pixels?access_token=${userAccessToken}`
      );
      const data = await res.json();
      if (data && data.data) {
        setAdAccountPixels(data.data);
      } else {
        console.error("Unexpected ad account pixels data:", data);
      }
    } catch (error) {
      console.error("Error fetching ad account pixels:", error);
    }
  };

  // ---------------------- Create a New Pixel (Business Only) ----------------------
  const createNewPixel = async () => {
    if (!selectedBusiness) {
      alert("Please select a business first.");
      return;
    }
    // Prompt the user for a pixel name.
    const pixelName = prompt("Enter the name for your new pixel:");
    if (!pixelName) {
      return;
    }
    try {
      const params = new URLSearchParams();
      params.append("name", pixelName);
      const res = await fetch(
        `https://graph.facebook.com/v18.0/${selectedBusiness.id}/owned_pixels?access_token=${userAccessToken}`,
        {
          method: "POST",
          body: params,
        }
      );
      const data = await res.json();
      if (data && data.id) {
        alert("Pixel created successfully!");
        // Refresh the list of pixels so the new pixel appears.
        fetchBusinessPixels(selectedBusiness.id);
      } else {
        console.error("Error creating pixel:", data);
        alert("Error creating pixel. Check console for details.");
      }
    } catch (error) {
      console.error("Error creating pixel:", error);
      alert("Error creating pixel. Check console for details.");
    }
  };

  // ---------------------- Handlers for Selection ----------------------
  // For Business Pixel source
  const handleBusinessChange = (e) => {
    const businessId = e.target.value;
    const business = businesses.find((b) => b.id === businessId);
    setSelectedBusiness(business);
    setBusinessPixels([]); // clear previous pixels
    setSelectedPixel(null);
    if (businessId) {
      fetchBusinessPixels(businessId);
    }
  };

  // For Ad Account Pixel source
  const handleAdAccountChange = (e) => {
    const adAccountId = e.target.value;
    const account = adAccounts.find((a) => a.id === adAccountId);
    setSelectedAdAccount(account);
    setAdAccountPixels([]); // clear previous pixels
    setSelectedPixel(null);
    if (adAccountId) {
      fetchAdAccountPixels(adAccountId);
    }
  };

  // Handle pixel selection from list (works for either source)
  const handlePixelSelection = (e) => {
    const pixelId = e.target.value;
    let pixel = null;
    if (pixelSource === "business") {
      pixel = businessPixels.find((p) => p.id === pixelId);
    } else {
      pixel = adAccountPixels.find((p) => p.id === pixelId);
    }
    setSelectedPixel(pixel);
  };

  // ---------------------- Exchange for Long-Lived Token ----------------------
  const exchangeForLongLivedToken = async () => {
    try {
      const res = await fetch(
        `https://graph.facebook.com/v18.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${APP_ID}&client_secret=${APP_SECRET}&fb_exchange_token=${userAccessToken}`
      );
      const data = await res.json();
      if (data && data.access_token) {
        setLongLivedToken(data.access_token);
      } else {
        console.error("Error exchanging token:", data);
        alert("Error exchanging token. Check console for details.");
      }
    } catch (error) {
      console.error("Error exchanging token:", error);
    }
  };

  const proceedToConfirmation = () => {
    if (!selectedPixel) {
      alert("Please select a Facebook Pixel.");
      return;
    }
    setStep("confirmation");
  };

  // ---------------------- Render Steps ----------------------

  // Step 1: Login View
  const renderLoginStep = () => (
    <div style={styles.stepContainer}>
      <h2>Login with Facebook</h2>
      <p>Please log in with Facebook to continue.</p>
      <button onClick={handleLogin} style={styles.button}>
        Login with Facebook
      </button>
    </div>
  );

  // Step 2: Pixel Source and Pixel Selection
  const renderSelectStep = () => (
    <div style={styles.stepContainer}>
      <h2>Select Your Facebook Pixel</h2>

      <div style={styles.radioGroup}>
        <label style={styles.radioLabel}>
          <input
            type="radio"
            value="business"
            checked={pixelSource === "business"}
            onChange={(e) => {
              setPixelSource(e.target.value);
              setSelectedPixel(null);
            }}
          />
          Business Pixel
        </label>
        <label style={styles.radioLabel}>
          <input
            type="radio"
            value="ad_account"
            checked={pixelSource === "ad_account"}
            onChange={(e) => {
              setPixelSource(e.target.value);
              setSelectedPixel(null);
            }}
          />
          Ad Account Pixel
        </label>
      </div>

      {pixelSource === "business" && (
        <div style={styles.selectionSection}>
          {businesses.length > 0 ? (
            <div>
              <select onChange={handleBusinessChange} style={styles.select}>
                <option value="">-- Select Business --</option>
                {businesses.map((business) => (
                  <option key={business.id} value={business.id}>
                    {business.name}
                  </option>
                ))}
              </select>
              {selectedBusiness && (
                <div>
                  <h3>Available Pixels for {selectedBusiness.name}:</h3>
                  {businessPixels.length > 0 ? (
                    <ul style={styles.pixelList}>
                      {businessPixels.map((pixel) => (
                        <li key={pixel.id} style={styles.pixelItem}>
                          <label>
                            <input
                              type="radio"
                              name="pixel"
                              value={pixel.id}
                              onChange={handlePixelSelection}
                              checked={
                                selectedPixel && selectedPixel.id === pixel.id
                              }
                            />
                            {pixel.name || "Unnamed Pixel"} (ID: {pixel.id})
                          </label>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div>
                      <p>No pixels found for this business.</p>
                      <button
                        onClick={createNewPixel}
                        style={styles.buttonSmall}
                      >
                        Create New Pixel
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <p>No Business accounts found.</p>
          )}
        </div>
      )}

      {pixelSource === "ad_account" && (
        <div style={styles.selectionSection}>
          {adAccounts.length > 0 ? (
            <div>
              <select onChange={handleAdAccountChange} style={styles.select}>
                <option value="">-- Select Ad Account --</option>
                {adAccounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name || account.account_id}
                  </option>
                ))}
              </select>
              {selectedAdAccount && (
                <div>
                  <h3>
                    Available Pixels for Ad Account:{" "}
                    {selectedAdAccount.name || selectedAdAccount.account_id}
                  </h3>
                  {adAccountPixels.length > 0 ? (
                    <ul style={styles.pixelList}>
                      {adAccountPixels.map((pixel) => (
                        <li key={pixel.id} style={styles.pixelItem}>
                          <label>
                            <input
                              type="radio"
                              name="pixel"
                              value={pixel.id}
                              onChange={handlePixelSelection}
                              checked={
                                selectedPixel && selectedPixel.id === pixel.id
                              }
                            />
                            {pixel.name || "Unnamed Pixel"} (ID: {pixel.id})
                          </label>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>
                      No pixels found for this ad account. Please create a pixel
                      from your Business Manager if needed.
                    </p>
                  )}
                </div>
              )}
            </div>
          ) : (
            <p>No Ad Accounts found.</p>
          )}
        </div>
      )}

      <button onClick={proceedToConfirmation} style={styles.button}>
        Next: Confirmation
      </button>
    </div>
  );

  // Step 3: Confirmation View (with Long-Lived Token Exchange)
  const renderConfirmationStep = () => (
    <div style={styles.stepContainer}>
      <h2>Confirmation</h2>
      <div>
        <h3>Pixel Source</h3>
        <p>
          {pixelSource === "business"
            ? selectedBusiness
              ? `${selectedBusiness.name} (Business ID: ${selectedBusiness.id})`
              : "No Business selected."
            : selectedAdAccount
            ? `${
                selectedAdAccount.name || selectedAdAccount.account_id
              } (Ad Account ID: ${selectedAdAccount.id})`
            : "No Ad Account selected."}
        </p>
      </div>
      <div>
        <h3>Selected Facebook Pixel</h3>
        {selectedPixel ? (
          <p>
            {selectedPixel.name || "Unnamed Pixel"} (ID: {selectedPixel.id})
          </p>
        ) : (
          <p>No Facebook Pixel selected.</p>
        )}
      </div>
      <div style={{ marginTop: "20px" }}>
        <button onClick={exchangeForLongLivedToken} style={styles.button}>
          Get Long-Lived Token
        </button>
        {longLivedToken && (
          <div style={{ marginTop: "10px", wordBreak: "break-all" }}>
            <h4>Long-Lived Token:</h4>
            <p>{longLivedToken}</p>
          </div>
        )}
      </div>
    </div>
  );

  // ---------------------- Main Render ----------------------
  return (
    <div style={styles.container}>
      {step === "login" && renderLoginStep()}
      {step === "select" && renderSelectStep()}
      {step === "confirmation" && renderConfirmationStep()}
    </div>
  );
};

// ---------------------- Inline Styles ----------------------
const styles = {
  container: {
    maxWidth: "600px",
    margin: "20px auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  stepContainer: {
    textAlign: "center",
  },
  button: {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4267B2",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  buttonSmall: {
    marginTop: "10px",
    padding: "6px 12px",
    fontSize: "14px",
    backgroundColor: "#4267B2",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    margin: "20px 0",
  },
  radioLabel: {
    fontSize: "16px",
  },
  selectionSection: {
    marginTop: "20px",
  },
  select: {
    padding: "10px",
    fontSize: "16px",
    margin: "10px 0",
  },
  pixelList: {
    listStyleType: "none",
    padding: 0,
    textAlign: "left",
  },
  pixelItem: {
    marginBottom: "10px",
  },
};

export default FacebookPixelSelectorUnified;
