// Helper/steadFastEntry.js
import myAxios from '../AxiosUrl';
import { toast } from 'react-toastify';

export const steadFastEntryHelper = (
    uid,
    selectedItems,
    setSteadFastLoading,
    setSelectedItems
) => {
    setSteadFastLoading(true);
    myAxios
        .post(`steadfast/${uid}`, selectedItems)
        .then((res) => {
            setSteadFastLoading(false);
            setSelectedItems([]);
            toast.success(res.data);
        })
        .catch((error) => {
            console.log(error);
            setSteadFastLoading(false);
        });
};
