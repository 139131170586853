import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Button,
    Box,
    CircularProgress,
} from '@mui/material';

function OrderTable({
    OrderD,
    setmodalData,
    setOpenModal,
    setRefresh,
    setPrintModalData,
    setOpenPrint,
    selectedItems,
    handleToggleItem,
    handleToggleAll,
    selectAll,
    navigate,
    AlartSubmit,
    loading,
}) {
    // Helper: Convert numeric status to human-readable text
    const getOrderStatusText = (status) => {
        const statusMap = {
            1: 'New Order',
            2: 'Call Done',
            3: 'Courier Done',
            4: 'Print Done',
            5: 'Cancel',
            6: 'Return',
            7: 'Call Not-Receice',
            8: 'Ask-Advance',
            9: 'Ship-Later',
            10: 'Waiting',
            11: 'Overseas',
            12: 'Delivery-Issue',
        };
        return statusMap[status] || '';
    };

    // Limit name to max 13 characters
    const formatName = (name) => {
        const maxChars = 13;
        return name.length > maxChars ? name.slice(0, maxChars) + '..' : name;
    };

    // Handle cell click (opens modal, etc.)
    const handleCellClick = (item) => {
        setmodalData(item);
        setOpenModal(true);
        setRefresh(false);
    };

    // If loading, show a loading spinner
    if (loading) {
        return (
            <div
                style={{
                    padding: '20px',
                    textAlign: 'center',
                    color: '#888',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '150px',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    // If not loading and no data, show "No Data Found"
    if (OrderD.length === 0) {
        return (
            <div
                style={{
                    padding: '20px',
                    textAlign: 'center',
                    color: '#888',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '150px',
                }}
            >
                No Data Found
            </div>
        );
    }

    // Define columns for header and cells
    const columns = [
        {
            id: 'name',
            label: 'Name',
            width: 160,
            align: 'left',
            render: (item) => (
                <>
                    <Checkbox
                        checked={selectedItems.includes(item)}
                        onChange={() => handleToggleItem(item)}
                        size="small"
                    />
                    {formatName(item.cName)}
                </>
            ),
        },
        {
            id: 'mobile',
            label: 'Mobile',
            width: 90,
            align: 'center',
            render: (item) => (
                <span onClick={() => handleCellClick(item)}>
                    {item.cMobile}
                </span>
            ),
        },
        {
            id: 'amount',
            label: 'Amount',
            width: 70,
            align: 'center',
            render: (item) => (
                <span onClick={() => handleCellClick(item)}>
                    {item.totalPrice}
                </span>
            ),
        },
        {
            id: 'item',
            label: 'Item',
            width: 40,
            align: 'center',
            render: (item) => (
                <span onClick={() => handleCellClick(item)}>{item.item}</span>
            ),
        },
        {
            id: 'assigned',
            label: 'Assigned',
            width: 82,
            align: 'center',
            render: (item) => (
                <span onClick={() => handleCellClick(item)}>
                    {item.assignedName || 'Admin'}
                </span>
            ),
        },
        {
            id: 'status',
            label: 'Status',
            width: 120,
            align: 'center',
            render: (item) => (
                <Button
                    variant="outlined"
                    size="small"
                    color={item.orderStatus === 5 ? 'error' : 'primary'}
                    sx={{
                        ml: 1,
                        fontSize: '0.7rem',
                        padding: '4px',
                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                    }}
                    onClick={() => handleCellClick(item)}
                >
                    {getOrderStatusText(item.orderStatus)}
                </Button>
            ),
        },
        {
            id: 'actions',
            label: 'Edit / Delete / Print',
            width: 220,
            align: 'right',
            render: (item) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => navigate('/edit-order', { state: item })}
                        sx={{ mr: 1, fontSize: '0.75rem', padding: '4px' }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => AlartSubmit(item._id)}
                        sx={{ mr: 1, fontSize: '0.75rem', padding: '4px' }}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setPrintModalData(item);
                            setOpenPrint(true);
                            setRefresh(false);
                        }}
                        sx={{ fontSize: '0.75rem', padding: '4px' }}
                    >
                        Print
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <TableContainer
            component={Paper}
            style={{ borderRadius: 0, overflowX: 'auto' }}
        >
            <Table
                sx={{ minWidth: { xs: 300, sm: 650 } }}
                size="small"
                aria-label="simple table"
            >
                <TableHead style={{ backgroundColor: '#f4f4f4' }}>
                    <TableRow
                        sx={{
                            '& > .MuiTableCell-root:not(:last-child)': {
                                borderRight: '1px solid #ddd',
                                padding: '8px',
                            },
                        }}
                    >
                        {columns.map((col) => (
                            <TableCell
                                key={col.id}
                                align={col.align}
                                sx={{ width: col.width, padding: '8px' }}
                            >
                                {col.id === 'name' ? (
                                    <>
                                        <Checkbox
                                            checked={selectAll}
                                            onChange={handleToggleAll}
                                            size="small"
                                        />
                                        {col.label}
                                    </>
                                ) : (
                                    col.label
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {OrderD.map((item) => (
                        <TableRow
                            key={item._id}
                            sx={{
                                '& > .MuiTableCell-root:not(:last-child)': {
                                    borderRight: '1px solid #ddd',
                                    padding: '8px',
                                },
                            }}
                        >
                            {columns.map((col) => (
                                <TableCell
                                    key={col.id}
                                    align={col.align}
                                    sx={{ width: col.width, padding: '8px' }}
                                >
                                    {col.render(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OrderTable;
