import React, { useState, useEffect, useRef } from 'react';
import myAxios from '../AxiosUrl';
import './Dashboard.css';
import BarChart from './BarChart';
import ColorPicker from './ColorPicker';
import ReactLoading from 'react-loading';
function Dashboard() {
    const uid = localStorage.getItem('id');
    const [dashboard, setDashboard] = useState([]);

    const [city, setCity] = useState([]);
    const [LoadingCity, setLoadingCity] = useState(false);

    const [dashToday, setDashToday] = useState([]);
    const [dashYesterday, setDashYesterday] = useState([]);
    const [dashMonth, setDashMonth] = useState([]);
    const [dashLastMonth, setDashLastMonth] = useState([]);

    const [VisitToday, setVisitToday] = useState([]);
    const [VisitYesterday, setVisitYesterday] = useState([]);
    const [VistMonth, setVistMonth] = useState([]);
    const [VisitLastMonth, setVisitLastMonth] = useState([]);

    const [message, setMessage] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [loadingDash, setLoadingDash] = useState(false);

    const [loadingDay, setLoadingDay] = useState(false);
    const [loadingYes, setLoadingYes] = useState(false);
    const [loadingMonth, setLoadingMonth] = useState(false);
    const [loadingLMonth, setLoadingLMonth] = useState(false);

    const [loadingVDay, setloadingVDay] = useState(false);
    const [loadingVYes, setloadingVYes] = useState(false);
    const [loadingVMonth, setloadingVMonth] = useState(false);
    const [loadingVLMonth, setloadingVLMonth] = useState(false);

    useEffect(() => {
        setLoadingCity(true);
        myAxios
            .get(`get-city/${uid}/today`)
            .then((res) => {
                setLoadingCity(false);
                setCity(res.data);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.msg == 'No token, authorization denied') {
                    localStorage.removeItem('token');
                    localStorage.removeItem('id');
                    localStorage.removeItem('IsSuper');
                    localStorage.removeItem('IsTeam');
                }
            });
    }, []);

    const getdata = () => {
        setLoadingDash(true);
        myAxios
            .get(`dashboard/${uid}`)
            .then((res) => {
                setLoadingDash(false);
                setDashboard(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getMessage = () => {
        setLoadingMessage(true);
        myAxios
            .get(`get-message/${uid}`)
            .then((res) => {
                setLoadingMessage(false);
                setMessage(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const DashToday = () => {
        setLoadingDay(true);
        myAxios
            .get(`dash-today/${uid}`)
            .then((res) => {
                setLoadingDay(false);
                setDashToday(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const DashYesterday = () => {
        setLoadingYes(true);
        myAxios
            .get(`dash-yesterday/${uid}`)
            .then((res) => {
                setLoadingYes(false);
                setDashYesterday(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const DashMonth = () => {
        setLoadingMonth(true);
        myAxios
            .get(`dash-month/${uid}`)
            .then((res) => {
                setLoadingMonth(false);
                setDashMonth(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const DashLastMonth = () => {
        setLoadingLMonth(true);
        myAxios
            .get(`dash-last-month/${uid}`)
            .then((res) => {
                setLoadingLMonth(false);
                setDashLastMonth(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const VToday = () => {
        setloadingVDay(true);
        myAxios
            .get(`visitor-today/${uid}`)
            .then((res) => {
                setloadingVDay(false);
                setVisitToday(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const VYesterday = () => {
        setloadingVYes(true);
        myAxios
            .get(`visitor-yesterday/${uid}`)
            .then((res) => {
                setloadingVYes(false);
                setVisitYesterday(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const VMonth = () => {
        setloadingVMonth(true);
        myAxios
            .get(`visitor-month/${uid}`)
            .then((res) => {
                setloadingVMonth(false);
                setVistMonth(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const VLastMonth = () => {
        setloadingVLMonth(true);
        myAxios
            .get(`visitor-last-month/${uid}`)
            .then((res) => {
                setloadingVLMonth(false);
                setVisitLastMonth(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        VToday();
        VYesterday();
        VMonth();
        VLastMonth();
        getdata();
        getMessage();
        DashToday();
        DashYesterday();
        DashMonth();
        DashLastMonth();
    }, []);

    return (
        <div style={{ minHeight: '90vh' }}>
            <div className="dashboard-Video">
                <div>
                    ড্যাশবোর্ড কিভাবে কাজ করে দেখুন ➡️{' '}
                    <a href="#" target="_blank">
                        VIDEO LINK
                    </a>{' '}
                </div>
            </div>
            <div className="mainCity">
                <div className="CityTitle">
                    <h3 style={{ padding: '4px', margin: '0px' }}>
                        Order From City
                    </h3>
                </div>

                {LoadingCity ? (
                    <ReactLoading
                        className="loading"
                        type="cylon"
                        color="#2b3ed6"
                        height={'40px'}
                        width={'40px'}
                    />
                ) : (
                    <div className="CityItem">
                        {city.length > 0 ? (
                            city.map((data, index) => {
                                return (
                                    <div className="city-number" key={index}>
                                        <p className="city">{data.City}</p>
                                        <p className="cityNumber">
                                            {data.orders}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{ padding: '20px' }}>No data..</div>
                        )}
                    </div>
                    // <div style={{ margin: "0 auto" }}>
                    //   {/* {city.length && <BarChart data={city} />} */}
                    // </div>
                )}
            </div>
            <div className="mainDashboard">
                <div className="dashboard-item">
                    <div className="dash-box">
                        {loadingDay ? (
                            <ReactLoading
                                type="cylon"
                                color="#2b3ed6"
                                height={'40px'}
                                width={'40px'}
                            />
                        ) : (
                            <>
                                <p>
                                    Today:{' '}
                                    {dashToday.sale ? dashToday.sale : '0'}
                                </p>
                                <p>
                                    Today: ৳
                                    {dashToday.taka ? dashToday.taka : '0'}
                                </p>
                                <p>
                                    Profit: ৳
                                    {dashToday.Profit ? dashToday.Profit : '0'}
                                </p>
                                <p>
                                    Return:{' '}
                                    {dashToday.Return ? dashToday.Return : '0'}
                                </p>
                                <p>
                                    Advance: ৳
                                    {dashToday.advance
                                        ? dashToday.advance
                                        : '0'}
                                </p>
                                <p>
                                    Discount: ৳
                                    {dashToday.discount
                                        ? dashToday.discount
                                        : '0'}
                                </p>
                            </>
                        )}
                    </div>
                    <div className="dash-box">
                        {loadingYes ? (
                            <ReactLoading
                                type="cylon"
                                color="#2b3ed6"
                                height={'40px'}
                                width={'40px'}
                            />
                        ) : (
                            <>
                                <p>
                                    Yesterday:{' '}
                                    {dashYesterday.sale
                                        ? dashYesterday.sale
                                        : '0'}
                                </p>
                                <p>
                                    Yesterday: ৳
                                    {dashYesterday.taka
                                        ? dashYesterday.taka
                                        : '0'}
                                </p>
                                <p>Profit: ৳{dashYesterday.Profit}</p>
                                <p>
                                    Return:{' '}
                                    {dashYesterday.Return
                                        ? dashYesterday.Return
                                        : '0'}
                                </p>
                                <p>Advance: ৳{dashYesterday.advance}</p>
                                <p>
                                    Discount: ৳
                                    {dashYesterday.discount
                                        ? dashYesterday.discount
                                        : '0'}
                                </p>
                            </>
                        )}
                    </div>

                    <div className="dash-box">
                        {loadingMonth ? (
                            <ReactLoading
                                type="cylon"
                                color="#2b3ed6"
                                height={'40px'}
                                width={'40px'}
                            />
                        ) : (
                            <>
                                <p>
                                    Month:{' '}
                                    {dashMonth.sale ? dashMonth.sale : '0'}
                                </p>
                                <p>
                                    Month: ৳
                                    {dashMonth.taka ? dashMonth.taka : '0'}
                                </p>
                                <p>
                                    Profit: ৳
                                    {dashMonth.Profit ? dashMonth.Profit : '0'}
                                </p>
                                <p>
                                    Return:{' '}
                                    {dashMonth.Return ? dashMonth.Return : '0'}
                                </p>
                                <p>
                                    Advance: ৳
                                    {dashMonth.advance
                                        ? dashMonth.advance
                                        : '0'}
                                </p>
                                <p>
                                    Discount: ৳
                                    {dashMonth.discount
                                        ? dashMonth.discount
                                        : '0'}
                                </p>
                            </>
                        )}
                    </div>

                    <div className="dash-box">
                        {loadingLMonth ? (
                            <ReactLoading
                                type="cylon"
                                color="#2b3ed6"
                                height={'40px'}
                                width={'40px'}
                            />
                        ) : (
                            <>
                                <p>
                                    Last Month:{' '}
                                    {dashLastMonth.sale
                                        ? dashLastMonth.sale
                                        : '0'}
                                </p>
                                <p>
                                    Last Month: ৳
                                    {dashLastMonth.taka
                                        ? dashLastMonth.taka
                                        : '0'}
                                </p>
                                <p>
                                    Profit: ৳
                                    {dashLastMonth.Profit
                                        ? dashLastMonth.Profit
                                        : '0'}
                                </p>
                                <p>
                                    Return:{' '}
                                    {dashLastMonth.Return
                                        ? dashLastMonth.Return
                                        : '0'}
                                </p>
                                <p>
                                    Advance: ৳
                                    {dashLastMonth.advance
                                        ? dashLastMonth.advance
                                        : '0'}
                                </p>
                                <p>
                                    Discount: ৳
                                    {dashLastMonth.discount
                                        ? dashLastMonth.discount
                                        : '0'}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="mainDashboard">
                <div className="dashboard-item">
                    <div className="dash-box">
                        <h1>
                            {loadingVDay ? (
                                <ReactLoading
                                    type="cylon"
                                    color="#2b3ed6"
                                    height={'40px'}
                                    width={'40px'}
                                />
                            ) : (
                                VisitToday.today
                            )}
                        </h1>
                        <p>Visitor Today</p>
                    </div>
                    <div className="dash-box">
                        <h1>
                            {loadingVYes ? (
                                <ReactLoading
                                    type="cylon"
                                    color="#2b3ed6"
                                    height={'40px'}
                                    width={'40px'}
                                />
                            ) : (
                                VisitYesterday.yesterday
                            )}
                        </h1>
                        <p>Visitor Yesterday</p>
                    </div>
                    <div className="dash-box">
                        <h1>
                            {loadingVMonth ? (
                                <ReactLoading
                                    type="cylon"
                                    color="#2b3ed6"
                                    height={'40px'}
                                    width={'40px'}
                                />
                            ) : (
                                VistMonth.month
                            )}
                        </h1>
                        <p>Monthly Visitor</p>
                    </div>
                    <div className="dash-box">
                        <h1>
                            {loadingVLMonth ? (
                                <ReactLoading
                                    type="cylon"
                                    color="#2b3ed6"
                                    height={'40px'}
                                    width={'40px'}
                                />
                            ) : (
                                VisitLastMonth.lastMonth
                            )}
                        </h1>
                        <p>Last Month Visitor</p>
                    </div>
                </div>
            </div>
            {/* <div className="mainDashboard">
        <div className="dashboard-item">
          <div className="dash-box">
            <h1>
              {loadingMessage ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                message.today
              )}
            </h1>
            <p>Today Message</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingMessage ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                message.yesterday
              )}
            </h1>
            <p>Yesterday Message</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingMessage ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                message.month
              )}
            </h1>
            <p>Monthly Message</p>
          </div>
          <div className="dash-box">
            <h1>
              {loadingMessage ? (
                <ReactLoading
                  type="cylon"
                  color="#2b3ed6"
                  height={"40px"}
                  width={"40px"}
                />
              ) : (
                message.lastMonth
              )}
            </h1>
            <p>Last_Month Message</p>
          </div>
        </div>
      </div> */}
            <div className="mainDashboard">
                <div className="dashboard-item">
                    <div className="dash-box">
                        {loadingDash ? (
                            <ReactLoading
                                type="cylon"
                                color="#2b3ed6"
                                height={'40px'}
                                width={'40px'}
                            />
                        ) : (
                            <>
                                <h1>{dashboard.TotalInventory}</h1>
                                <p>Total Inventory</p>
                            </>
                        )}
                    </div>
                    <div className="dash-box">
                        {loadingDash ? (
                            <ReactLoading
                                type="cylon"
                                color="#2b3ed6"
                                height={'40px'}
                                width={'40px'}
                            />
                        ) : (
                            <>
                                <h1>৳ {dashboard.TotalInventoryValue}</h1>
                                <p>Inventory Value</p>
                            </>
                        )}
                    </div>
                    <div className="dash-box">
                        {loadingDash ? (
                            <ReactLoading
                                type="cylon"
                                color="#2b3ed6"
                                height={'40px'}
                                width={'40px'}
                            />
                        ) : (
                            <>
                                <h1>0</h1>
                                <p>Money Invest</p>
                            </>
                        )}
                    </div>
                    <div className="dash-box">
                        {loadingDash ? (
                            <ReactLoading
                                type="cylon"
                                color="#2b3ed6"
                                height={'40px'}
                                width={'40px'}
                            />
                        ) : (
                            <>
                                {' '}
                                <h1>0</h1>
                                <p>Money Available</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
